@use "../config/" as *;
@forward "../main-scss/";
@forward "../swiper/swiper-bundle";
@forward "../listing/listing-common";
@forward "../listing/news-listing";
@forward "../standing/standing-04";
@forward "../stats/stat-listing";
@forward "../scorestrip/scorestrip";
@forward "../showcase/showcase";
@forward "../listing/video-listing";
@forward "../partials/home-page-banners";
@forward "../listing/series-listing";
@forward "../showcase/marquee-showcase";
@forward "../listing/almanack-listing";
@forward "../partials/betting-site";
@forward "../listing/betting-listing";
.waf-listing.waf-home-news-listing {
    .article {
        &-list {
            @extend %mx-4-neg;
            @extend %px-4;
            > * {
                width: 90%;
            }
            @include mq(col-md) {
                margin-inline: 0;
                padding-inline: 0;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                > * {
                    width: 100%;
                }
            }
        }
        &-thumbnail {
            .img-box {
                border-radius: 0;
            }
        }
        &-meta {
            margin-top: var(--space-4);
            .meta-category {
                left: var(--space-4);
            }
        }
        &-content {
            background: var(--pure-white-900);
            padding-inline: var(--space-4);
            padding-bottom: var(--space-4);
        }
    }
}
.waf-scorestrip {
    background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-600) 133.57%);
    .waf-head {
        display: block;
    }
}
@include mq(col-md) {
    .waf-standings {
        padding-inline: 0;
    }
    .waf-listing.waf-home-news-listing {
        padding-bottom: 0;
    }
}
@include mq(col-lg) {
    .waf-row-news-standing {
        .col-lg-12 {
            display: flex;
            gap: var(--space-4);
            > section:nth-child(2) {
                width: var(--ads-width);
                flex-shrink: 0
            }
        }
        .article-item:nth-child(n+4) {
            display: none;
        }
    }
    .waf-listing.waf-home-news-listing {
        padding-block: var(--space-8) 0;
    }
    .hide-standings .waf-row-news-standing {
        .article-list {
            grid-template-columns: repeat(4, 1fr);
        }
        .article-item:nth-child(4) {
            display: block;
        }
        .waf-standings {
            display: none;
        }
        .site-content {
            flex: 1;
        }
        .site-content:nth-child(2) {
            display: none;
        }
    }
    .waf-home-video-listing {
        margin-top: var(--space-12);
    }
}